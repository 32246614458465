import { Logger } from '@frontend/Logger';
import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { addProduct, removeProduct, updateProduct } from '@frontend/product/redux';
import { Product } from '@frontend/product/types';

enum MessageTypes {
    UPDATED = 'product_updated',
    DELETED = 'product_deleted',
    CREATED = 'product_created'
}

export class ProductEventListener extends PubSubConnection {
    private static instance: ProductEventListener | null = null;
    #dispatch: ThunkDispatch<any, any, Action>;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('product');
        this.#dispatch = dispatch;
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): ProductEventListener {
        if (this.instance == null) {
            this.instance = new ProductEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Product; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Product; changes?: any }) {
        Logger.log('Updating product in store', { product: payload.data.id }, payload.data);
        this.#dispatch(updateProduct(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Product }) {
        Logger.log('Removing product from store', { product: payload.data.id }, payload.data);
        this.#dispatch(removeProduct(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Product; changes?: any }) {
        Logger.log('Adding product to store', { product: payload.data.id }, payload.data);
        this.#dispatch(addProduct(payload.data));
    }
}
