import { APIClient } from '@frontend/api-utils';
import { User, UserStateName, UserTriggerName } from '@frontend/user/types';

const endpoint = '/user-api/v1';

export class UserWorkflowClient extends APIClient {
    public static async updateUserState(accountId: string, userId: string, state: UserStateName): Promise<User> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/users/${userId}/change-state`, { state: state });
        return await this.handleResponse<User>(response);
    }

    public static async triggerUserState(accountId: string, userId: string, trigger: { trigger: UserTriggerName; source: any }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }

    public static async triggerUserStateFromToken(body: {source?: {driver_type?: string; driver_id?: string}, target?: {driver_type?: string; driver_id?: string}}): Promise<void> {
        const response = await this.post(`${endpoint}/trigger-from-token`, body);
        return await this.handleVoidResponse(response);
    }
}