import { Logger } from '@frontend/Logger';
import { DriverAuthenticationManager } from '@frontend/authentication-v2';
import { DeviceServiceEventListener } from '@frontend/device/events';
import { DriverType } from '@frontend/edge/types';
import { changeNavbar, changeView } from '@frontend/mobile-user-interface-templates';
import { PackageServiceEventListener } from '@frontend/package/events';
import { ProductEventListener } from '@frontend/product/events';
import { TransactionServiceEventListener } from '@frontend/transaction/events';
import { fetchUserInterfaces } from '@frontend/user-interface/redux';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { UserWorkflowClient } from '@frontend/user/api';
import { OldUserEventListener, UserEventListener } from '@frontend/user/events';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';

interface ViewProps {
    isLoading: boolean;
    view: string | null;
}

const useMain = (): ViewProps => {
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);
    const UIState = useAppSelector((state) => state.userInterfaces);
    const [driverEntityId, changeDriverEntityId] = useState<string | null>(null);
    const callback = (event: MessageEvent<string>) => {
        Logger.log('Mobile received event: ', {}, event);
        const payload = JSON.parse(event.data) as { message: string; data: any };

        if (payload.message === 'show_user_interface') {
            const data = payload.data as WorkflowStepTriggeredEvent;
            if (UIState && UIState.userInterfaces && UIState.userInterfaces.results) {
                const found = UIState.userInterfaces.results.find((ui) => ui.id == data.data.user_interface_id);
                if (found && found.type === UserInterfaceType.NAVBAR_BUTTONS) {
                    dispatch(changeNavbar(data.data.user_interface_id));
                } else {
                    dispatch(changeView({ view: data.data.user_interface_id, cache: data }));
                }
            }
        }
    };

    useEffect(() => {
        dispatch(fetchUserInterfaces({})).then(() => {
            DriverAuthenticationManager.getInstance()
                .waitForToken()
                .then((token) => changeDriverEntityId(token.entity_id));
        });
        DeviceServiceEventListener.getInstance(dispatch).addCallback('HOMEPAGE_DEVICE_LISTENER', callback);
        TransactionServiceEventListener.getInstance(dispatch).addCallback('HOMEPAGE_TRANSACTION_LISTENER', callback);
        PackageServiceEventListener.getInstance(dispatch).addCallback('HOMEPAGE_PACKAGE_LISTENER', callback);
        ProductEventListener.getInstance(dispatch).addCallback('HOMEPAGE_PRODUCT_LISTENER', callback);

        return () => {
            DeviceServiceEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_DEVICE_LISTENER');
            TransactionServiceEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_TRANSACTION_LISTENER');
            PackageServiceEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_PACKAGE_LISTENER');
            ProductEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_PRODUCT_LISTENER');
        };
    }, []);

    useEffect(() => {
        if (driverEntityId) {
            OldUserEventListener.getInstance(dispatch, [{ driver_entity_id: driverEntityId }])
                .addCallback('HOMEPAGE_USER_LISTENER', callback)
                .waitUntilConnected()
                .then(() => {
                    UserWorkflowClient.triggerUserStateFromToken({ source: { driver_type: DriverType.MOBILE, driver_id: driverEntityId } });
                });
            return () => {
                UserEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_USER_LISTENER');
            };
        }
        return () => {};
    }, [driverEntityId]);

    return {
        isLoading: (navigationState.current || navigationState.root) == null,
        view: navigationState.current || navigationState.root
    };
};

export default useMain;
