import { UserInterfaceButton } from '@frontend/user-interface-button/types';

import useFooterNavigationButton from './footer-navigation-button.controller';

const ID = 'footer-navigation-button';
export interface FooterNavigationButtonProps {
    button: UserInterfaceButton;
}

const FooterNavigationButton = (props: FooterNavigationButtonProps) => {
    const viewProps = useFooterNavigationButton(props);

    if (viewProps.icon == null) return <></>;
    return (
        <span
            id={ID}
            onClick={viewProps.onClick}
            className={`d-flex flex-column align-items-center justify-content-center text-${viewProps.color} mt-1 w-100`}>
            <viewProps.icon className={`h4 text-${viewProps.color} mb-1`} />
            <small className='text-xs'>{props.button.name}</small>
        </span>
    );
};

export default FooterNavigationButton;
