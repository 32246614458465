import { PasswordInput } from '@frontend/basic-forms';
import React from 'react';

import useChangePasswordForm from './change-password-form.controller';

const ID = 'change-password-form';
export interface ChangePasswordFormProps {
    onClose: () => void;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const viewProps = useChangePasswordForm(props);

    return (
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID}>
            <div className='modal-mobile-bottom'>
                <div className='modal-header d-flex justify-content-between align-items-center'>
                    <h3>Change password</h3>
                    <button
                        onClick={props.onClose}
                        className='btn btn-outline-secondary'>
                        x
                    </button>
                </div>
                <form className='modal-body d-flex flex-column align-items-center justify-content-between w-100'>
                    <div className='d-flex flex-column align-items-center w-100'>
                        <PasswordInput
                            id='old'
                            className='w-100'
                            required
                            label='Old password'
                            value={viewProps.formValues.old_password}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, old_password: value })}
                            isValidCallback={(value) => viewProps.changeValid(value)}
                            noConditionedStyling
                        />
                        <PasswordInput
                            className='w-100'
                            required
                            label='Password'
                            value={viewProps.formValues.password}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password: value })}
                            isValidCallback={(value) => viewProps.changeValid(value)}
                            noConditionedStyling
                            strength
                        />
                        <PasswordInput
                            id='confirm'
                            className='w-100'
                            required
                            label='Confirm password'
                            value={viewProps.formValues.password_confirmation}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password_confirmation: value })}
                            noConditionedStyling
                            toRepeat={viewProps.formValues.password}
                            isValidCallback={(value) => viewProps.changeValid(value)}
                        />
                    </div>

                    <button
                        className='btn btn-primary w-100 sticky-bottom'
                        type='submit'
                        onClick={viewProps.onSubmit}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
