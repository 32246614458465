import { Logger } from '@frontend/Logger';
import { SliceStatus, useLocalStorageState } from '@frontend/common';
import { changeView } from '@frontend/mobile-user-interface-templates';
import { fetchAccountUserInterfaceButtons } from '@frontend/user-interface-button/redux';
import { UserInterfaceButton, UserInterfaceButtonAction } from '@frontend/user-interface-button/types';
import { UserInterface } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';
import { FooterProps } from './footer.component';

interface ViewProps {
    isLoading: boolean;
    buttons: UserInterfaceButton[] | null;
}

const useFooter = (props: FooterProps): ViewProps => {
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);
    const UIState = useAppSelector((state) => state.userInterfaces);
    const userInterfaceButtonState = useAppSelector((state) => state.userInterfaceButtons);
    const [navbarInfo, changeNavbarInfo] = useLocalStorageState<string | null>('nvb_ui_id', null);
    const [userInterface, changeUserInterface] = useState<UserInterface | null>(null);
    const [buttons, changeButtons] = useLocalStorageState<UserInterfaceButton[] | null>('nvb_ui_btn_info', null);

    useEffect(() => {
        if (navigationState.navbar && navbarInfo != navigationState.navbar) {
            changeNavbarInfo(navigationState.navbar);
        }
    }, [navigationState.navbar]);

    useEffect(() => {
        if (UIState && UIState.userInterfaces && UIState.userInterfaces.results && navbarInfo) {
            const found = UIState.userInterfaces.results.find((ui) => ui.id == navbarInfo);
            if (found) {
                Logger.log('Found navbar UI', {}, found);
                changeUserInterface(found);
            }
        }
    }, [UIState, navbarInfo]);

    useEffect(() => {
        if (userInterface) {
            const listId = userInterface.account_id + ':' + userInterface.id;
            if (
                (userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface === null ||
                    userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId] == null) &&
                userInterfaceButtonState.status !== SliceStatus.ERROR
            )
                dispatch(
                    fetchAccountUserInterfaceButtons({
                        accountId: userInterface.account_id,
                        userInterfaceId: userInterface.id,
                        queryParams: { size: '100', index: '0' }
                    })
                );
            if (
                userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface != null &&
                userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId] != null
            )
                changeButtons([...userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId].results].sort((a, b) => a.order - b.order));
        }
    }, [userInterface, userInterfaceButtonState]);

    useEffect(() => {
        if (buttons && buttons.length > 0) {
            const defaultButton = buttons.find((button) => button.default) || buttons[0];
            switch (defaultButton.action) {
                case UserInterfaceButtonAction.NAVIGATION:
                    dispatch(changeView({ view: defaultButton.next_user_interface_id!, cache: null }));
                    break;
                case UserInterfaceButtonAction.TRIGGER:
                    // if (navigationState.cache === null) break;
                    // if (navigationState.cache.spot_id && navigationState.cache.transaction_id) {
                    //     AuthenticationManager.getInstance().getTokenWhenReady((token) => {
                    //         UserWorkflowClient.triggerUserState(navigationState.cache.account_id, token.entity_id, {

                    //         });
                    //     })
                    // }
                    break;
                case UserInterfaceButtonAction.DISPLAY:
                default:
                    break;
            }
        }
    }, [buttons]);

    return {
        isLoading: buttons === null || buttons.length === 0,
        buttons
    };
};

export default useFooter;
