import { navigationStore } from '@frontend/mobile-user-interface-templates';
import { userInterfaceButtonStore } from '@frontend/user-interface-button/redux';
import { userInterfaceStore } from '@frontend/user-interface/redux';
import { userStore } from '@frontend/user/redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        ...navigationStore,
        ...userInterfaceStore,
        ...userInterfaceButtonStore,
        ...userStore
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
