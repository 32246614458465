import React from 'react';
import { QrReader } from 'react-qr-reader';

import useScanCode from './scan-code.controller';

const ID = 'scan-code';
export interface ScanCodeProps {}

export const ScanCode = (props: ScanCodeProps) => {
    const viewProps = useScanCode(props);

    return (
        <div id={ID}>
            <QrReader
                onResult={(result: any, error) => {
                    if (result) {
                        console.log(result);
                    }
                }}
                constraints={{ facingMode: 'environment' }}
            />
        </div>
    );
};

export default ScanCode;
