import { Spinner } from '@frontend/elements';

import useProvision from './provision.controller';

const ID = 'provision';
export interface ProvisionProps {
    children?: React.ReactNode;
}

const Provision = (props: ProvisionProps) => {
    const viewProps = useProvision(props);

    if (viewProps.provisioned) return props.children;
    return (
        <div id={ID}>
            <Spinner />
        </div>
    );
};

export default Provision;
