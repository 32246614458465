import { Logger } from '@frontend/Logger';
import { ClassType, useLocalStorageState } from '@frontend/common';
import { changeView } from '@frontend/mobile-user-interface-templates';
import { UserInterfaceButtonAction, getIconByUIButtonType } from '@frontend/user-interface-button/types';
import { useEffect, useMemo } from 'react';
import { IconType } from 'react-icons';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { FooterNavigationButtonProps } from './footer-navigation-button.component';

interface ViewProps {
    onClick: () => void;
    icon: IconType | null;
    color: ClassType;
}

const useFooterNavigationButton = (props: FooterNavigationButtonProps): ViewProps => {
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);
    const [selected, changeSelected] = useLocalStorageState<string | null>('nvb_ui_btn_id', null);
    const icon = useMemo(() => getIconByUIButtonType(props.button.type), [props.button.type]);

    const color = useMemo(() => {
        return selected === props.button.id ? ClassType.LIGHT : ClassType.SECONDARY;
    }, [props.button.id, selected]);

    useEffect(() => {
        const view: string | null = navigationState.current ? navigationState.current : navigationState.root;
        if (selected !== props.button.id && view !== null && props.button.next_user_interface_id !== null && view === props.button.next_user_interface_id) {
            changeSelected(props.button.id);
        }
    }, [navigationState]);

    const onClick = () => {
        if (selected === props.button.id) return Logger.debug('Nav button already selected, skipping navigation', {}, props.button.id);
        changeSelected(props.button.id);
        switch (props.button.action) {
            case UserInterfaceButtonAction.NAVIGATION:
                dispatch(changeView({ view: props.button.next_user_interface_id!, cache: null }));
                break;
            case UserInterfaceButtonAction.TRIGGER:
                // if (navigationState.cache === null) break;
                // if (navigationState.cache.spot_id && navigationState.cache.transaction_id) {
                //     AuthenticationManager.getInstance().getTokenWhenReady((token) => {
                //         UserWorkflowClient.triggerUserState(navigationState.cache.account_id, token.entity_id, {

                //         });
                //     })
                // }
                break;
            case UserInterfaceButtonAction.DISPLAY:
            default:
                break;
        }
    };

    return {
        onClick,
        icon,
        color
    };
};

export default useFooterNavigationButton;
