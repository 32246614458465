import { APIClient } from "@frontend/api-utils";
import { AccessKey } from "@frontend/authentication/types";

const endpoint = '/authentication-api/v1';
export class AccessKeyClient extends APIClient {
    public static async generateQrCode(accountId: string, userId: string, accessKeyId: string): Promise<{qr_code: string}> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/users/${userId}/access-keys/${accessKeyId}/generate-qr-code`);
        return await this.handleResponse<{qr_code: string}>(response);
    }

    public static async generateAccessKey(accountId: string, userId: string): Promise<AccessKey> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/generate-access-key`);
        return await this.handleResponse<AccessKey>(response);
    }
}