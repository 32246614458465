import { Logger } from '@frontend/Logger';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MobileUserInterfaceNavigationState {
    navbar: string | null;
    root: string | null;
    current: string | null;
    cache: WorkflowStepTriggeredEvent | null;
    history: string[];
}

const initialState: MobileUserInterfaceNavigationState = {
    navbar: null,
    root: null,
    current: null,
    cache: null,
    history: []
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        initialiseNavigation: (state, action: PayloadAction<string | null>) => {
            if (action.payload == null) {
                state.root = UserInterfaceType.BUTTON_DASHBOARD;
            } else state.root = action.payload;
        },
        changeNavbar: (state, action: PayloadAction<string | null>) => {
            Logger.log('Navbar changed', {}, action.payload);
            state.navbar = action.payload;
        },
        changeView: (state, action: PayloadAction<{ view: string; cache: WorkflowStepTriggeredEvent | null } | null>) => {
            Logger.log('View changed', {}, action.payload);
            state.current = action.payload?.view || null;
            state.cache = action.payload?.cache || null;
        }
    }
});

export const navigationStore = { navigation: navigationSlice.reducer };
export const { initialiseNavigation, changeView, changeNavbar } = navigationSlice.actions;
