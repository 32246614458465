import { Spinner } from '@frontend/elements';

import useMain from './main.controller';
import RenderUserInterface from './render-user-interface/render-user-interface.component';

const ID = 'main';
const Main = () => {
    const viewProps = useMain();

    if (viewProps.isLoading || viewProps.view == null)
        return (
            <div id={ID}>
                <Spinner />
            </div>
        );

    return (
        <div id={ID}>
            <RenderUserInterface userInterfaceId={viewProps.view} />
        </div>
    );
};

export default Main;
