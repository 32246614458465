import logo from '../../assets/images/telloport_logo_horizontal_white.png';

const ID = 'header';
export interface HeaderProps {}

const Header = (props: HeaderProps) => {
    return (
        <header
            className='d-flex flex-row w-100 bg-primary min-height-50 sticky-top'
            id={ID}>
            <div className='d-flex flex-row justify-content-between align-items-center w-100 m-2'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    {/* <button
                        className='btn btn-primary shadow-none mb-0'
                        onClick={() => navigate(-1)}>
                        <FaChevronLeft />
                    </button> */}
                </div>
                <div className=''>
                    <img
                        src={logo}
                        className='img-fluid'
                        alt='logo'
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
