import FooterNavigationButton from './footer-navigation-button/footer-navigation-button.component';
import useFooter from './footer.controller';

const ID = 'footer';
export interface FooterProps {}

const Footer = (props: FooterProps) => {
    const viewProps = useFooter(props);
    return (
        <footer
            className='d-flex flex-row align-items-center w-100 bg-primary min-height-75 fixed-bottom'
            id={ID}>
            <div className='d-flex flex-row justify-content-around align-items-center w-100'>
                {viewProps.buttons?.map((button) => (
                    <FooterNavigationButton
                        button={button}
                        key={button.id}
                    />
                ))}
            </div>
        </footer>
    );
};

export default Footer;
