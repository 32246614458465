import { useAppSelector } from '@frontend/common';
import { fetchProfile, userStore } from '@frontend/user/redux';
import { User } from '@frontend/user/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProfileProps } from './profile.component';

interface ViewProps {
    updatePasswordShow: boolean;
    changeUpdatePasswordShow: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | undefined;
}

const useProfile = (props: ProfileProps): ViewProps => {
    const [user, changeUser] = useState<User | undefined>(undefined);
    const userState = useAppSelector(useSelector, userStore);
    const [updatePasswordShow, changeUpdatePasswordShow] = useState<boolean>(false);

    useEffect(() => {
        props.dispatch(fetchProfile());
    }, []);

    useEffect(() => {
        if (userState.current) changeUser(userState.current);
    }, [userState.current]);

    return {
        updatePasswordShow,
        changeUpdatePasswordShow,
        user
    };
};

export default useProfile;
