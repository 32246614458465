import { AuthenticationManager, DriverAuthenticationManager } from '@frontend/authentication-v2';
import { getCookie, setCookie } from '@frontend/common';
import { DriverBrand, DriverProvision, DriverType } from '@frontend/edge/types';
import { useEffect, useState } from 'react';

import { ProvisionProps } from './provision.component';

interface ViewProps {
    provisioned: boolean;
}

const PROVISION_COOKIE = 'drv_prs_info';
const useProvision = (props: ProvisionProps): ViewProps => {
    const [provisionInfo, changeProvisionInfo] = useState<DriverProvision | null>(getProvisionInfo());
    const [provisioned, changeProvisioned] = useState<boolean>(false);

    useEffect(() => {
        if (DriverAuthenticationManager.getInstance().hasValidToken()) {
            changeProvisioned(true);
        } else if (provisionInfo) {
            //This auth manager can afterwards be used to get the driver token and fetch driver and device info
            DriverAuthenticationManager.getInstance()
                .authenticate(provisionInfo)
                .then(() => changeProvisioned(true));
        } else {
            AuthenticationManager.getInstance()
                .waitForToken()
                .then((token) => {
                    setProvisionInfo(
                        {
                            identifier: crypto.randomUUID(),
                            type: DriverType.MOBILE,
                            brand: DriverBrand.TELLOPORT,
                            user_id: token.entity_id
                        },
                        changeProvisionInfo
                    );
                });
        }
    }, [provisionInfo]);

    return { provisioned };
};

export default useProvision;

function getProvisionInfo(): DriverProvision | null {
    const info = getCookie(PROVISION_COOKIE);
    if (info) {
        return JSON.parse(info);
    }
    return null;
}

function setProvisionInfo(provisionInfo: DriverProvision, callback?: (provisionInfo: DriverProvision) => void) {
    setCookie(PROVISION_COOKIE, JSON.stringify(provisionInfo));
    if (callback) callback(provisionInfo);
}
