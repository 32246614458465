import { Logger } from '@frontend/Logger';
import { Spinner } from '@frontend/elements';
import { AuthenticationQr, Profile, ScanCode } from '@frontend/mobile-user-interface-templates';
import { UserInterfaceType } from '@frontend/user-interface/types';

import useRenderUserInterface from './render-user-interface.controller';

export interface RenderUserInterfaceProps {
    userInterfaceId: string;
}

const RenderUserInterface = (props: RenderUserInterfaceProps) => {
    const viewProps = useRenderUserInterface(props);
    if (viewProps.isLoading) return <Spinner />;
    Logger.log('UI found: ' + viewProps.userInterface?.type);
    switch (viewProps.userInterface?.type) {
        case UserInterfaceType.SCAN_CODE:
            return <ScanCode />;
        case UserInterfaceType.PROFILE:
            return <Profile dispatch={viewProps.dispatch} />;
        case UserInterfaceType.AUTHENTICATION_QR:
            return <AuthenticationQr />;
        case UserInterfaceType.NAVBAR_BUTTONS:
            return <></>;
        default:
            return <div>oops somthing went wrong</div>;
    }
};

export default RenderUserInterface;
