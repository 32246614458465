import { useScripts } from '@frontend/common';
import { TranslationProvider } from '@frontend/lang';
import { ToastContainer } from 'react-toastify';

import Authentication from './authentication/authentication.component';
import Footer from './footer/footer.component';
import Header from './header/header.component';
import Main from './main/main.component';
import Provision from './provision/provision.component';

/**
 * TODO:
 * static pages should be rendered without using routes
 * all routes should be removed
 * render static pages by removing the UI id and injecting a specific key
 *
 * (question) do we even need static UI's?
 *
 * after login provision this device and store the identifier somewhere persistent
 * this is needed to have a sensor to scan badges
 * for now the mobile app should only be able to scan qr-codes
 */

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');

    return (
        <TranslationProvider>
            <div className='min-height-300 bg-primary position-absolute w-100 sticky-top top-bar' />
            <Authentication>
                <Provision>
                    <Header />
                    <main className='main-content position-relative'>
                        <div className='container-fluid p-0 m-0'>
                            <Main />
                        </div>
                        <div className='min-height-75'>{/** bottom offset for footer */}</div>
                    </main>
                    <Footer />
                </Provision>
            </Authentication>
            <ToastContainer />
        </TranslationProvider>
    );
}

export default App;
