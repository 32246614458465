import { AuthenticationManager } from '@frontend/authentication-v2';
import { StringPlaceholder } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaUser, FaUserAlt } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';

import ChangePasswordForm from './change-password-form/change-password-form.component';
import useProfile from './profile.controller';

export interface ProfileProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const Profile = (props: ProfileProps) => {
    const viewProps = useProfile(props);

    return (
        <div
            id={UserInterfaceType.PROFILE}
            className='d-flex flex-column w-100'>
            <div className='card m-2'>
                <div className='card-body'>
                    <span className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='card d-flex p-4'>
                            <FaUser />
                            <div className='mask bg-gradient-secondary border-radius-lg'></div>
                        </div>

                        <div className='d-flex flex-column text-end ms-3'>
                            <StringPlaceholder
                                loaded={!!viewProps.user}
                                type='glow'>
                                <>
                                    <h5>
                                        {viewProps.user?.first_name} {viewProps.user?.last_name}
                                    </h5>
                                    <p className='p-0 m-0'>{viewProps.user?.email}</p>
                                </>
                            </StringPlaceholder>
                        </div>
                    </span>
                </div>
                <div className='card-footer'>
                    <button
                        className='btn btn-primary w-100 mb-0'
                        onClick={() => AuthenticationManager.getInstance().doLogout()}
                        type='button'>
                        <span className='d-flex flex-row align-items-center'>
                            <FaUserAlt />
                            <span className='ps-2'>
                                <FormattedMessage
                                    id='Mobile.Profile.SignOutButton'
                                    description='The label inside the sign out button on the mobile app (profile page)'
                                    defaultMessage='Sign out'
                                />
                            </span>
                        </span>
                    </button>
                </div>
            </div>
            <button
                className='btn btn-light m-2'
                onClick={() => viewProps.changeUpdatePasswordShow(true)}>
                <span className='d-flex flex-row justify-content-start align-items-center'>
                    <FaKey />
                    <span className='ps-2'>Change password</span>
                </span>
            </button>

            {viewProps.updatePasswordShow && <ChangePasswordForm onClose={() => viewProps.changeUpdatePasswordShow(false)} />}
        </div>
    );
};

export default Profile;
