import { Spinner } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';
import QRCode from 'react-qr-code';

import useAuthenticationQr from './authentication-qr.controller';

export const AuthenticationQr = () => {
    const viewProps = useAuthenticationQr();

    return (
        <div
            id={UserInterfaceType.AUTHENTICATION_QR}
            className='d-flex flex-column align-items-center'>
            <h3 className='text-white'>Access key</h3>
            <div className='text-center mt-3'>
                {viewProps.qrCode ? (
                    <div className='card'>
                        <div className='card-body p-3'>
                            <QRCode value={viewProps.qrCode} />
                        </div>
                    </div>
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    );
};

export default AuthenticationQr;
