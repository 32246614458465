import { Logger } from '@frontend/Logger';
import { UserInterface } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { RenderUserInterfaceProps } from './render-user-interface.component';

interface ViewProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterface | null;
    isLoading: boolean;
}

const useRenderUserInterface = (props: RenderUserInterfaceProps): ViewProps => {
    const dispatch = useAppDispatch();
    const UIState = useAppSelector((state) => state.userInterfaces);
    const [userInterface, changeUserInterface] = useState<UserInterface | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (UIState && UIState.userInterfaces && UIState.userInterfaces.results) {
            changeIsLoading(false);
            const found = UIState.userInterfaces.results.find((ui) => ui.id == props.userInterfaceId);
            if (found) {
                Logger.log('Found user interface', {}, found);
                changeUserInterface(found);
            }
        } else changeIsLoading(true);
    }, [props.userInterfaceId, UIState]);

    return {
        dispatch,
        userInterface,
        isLoading
    };
};

export default useRenderUserInterface;
