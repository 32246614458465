import { BasicAuthClient } from '@frontend/authentication-methods/api';
import { BasicAuth, ChangePassword } from '@frontend/authentication-methods/types';
import { ToastUtil } from '@frontend/toast-utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ChangePasswordFormProps } from './change-password-form.component';

interface ViewProps {
    formValues: ChangePassword;
    changeFormValues: Dispatch<SetStateAction<ChangePassword>>;
    onSubmit: (e: any) => void;
    submitted: boolean;
    valid: boolean;
    changeValid: Dispatch<SetStateAction<boolean>>;
}

const useChangePasswordForm = (props: ChangePasswordFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<ChangePassword>({});
    const [basicAuth, changeBasicAuth] = useState<BasicAuth | undefined>(undefined);
    const [submitted, changeSubmitted] = useState(false);
    const [valid, changeValid] = useState(false);

    useEffect(() => {
        BasicAuthClient.fetchBasicAuths().then((basicAuths) => {
            if (basicAuths.results.length > 0) changeBasicAuth(basicAuths.results[0]);
        });
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!basicAuth) return;
        changeSubmitted(true);
        BasicAuthClient.changePassword(basicAuth.user_id, basicAuth.id, formValues)
            .then(() => {
                ToastUtil.success('Success', 'Password changed.');
                props.onClose();
            })
            .catch(() => {
                ToastUtil.error('Error', 'Invalid credentials.');
            })
            .finally(() => changeSubmitted(false));
    };

    return { formValues, changeFormValues, onSubmit, submitted, valid, changeValid };
};

export default useChangePasswordForm;
