import { AccountClient } from '@frontend/account/api';
import { Account } from '@frontend/account/types';
import { AuthenticationManager } from '@frontend/authentication-v2';
import { AccessKeyClient } from '@frontend/authentication/api';
import { AccessKey } from '@frontend/authentication/types';
import { useEffect, useState } from 'react';

interface ViewProps {
    qrCode: string | null;
}
//TODO calc time between create and end date from accKey -> then display a bar that shows how much time is left
const useAuthenticationQr = (): ViewProps => {
    const [accounts, changeAccounts] = useState<Account[] | null>(null);
    const [accessKey, changeAccessKey] = useState<AccessKey | null>(null);
    const [qrCode, changeQrCode] = useState<string | null>(null);

    useEffect(() => {
        AccountClient.fetchAccounts().then((response) => changeAccounts(response.results));
    }, []);

    useEffect(() => {
        if (accounts && accounts.length > 0)
            AuthenticationManager.getInstance()
                .waitForToken()
                .then((token) => {
                    AccessKeyClient.generateAccessKey(accounts[0].id, token.entity_id).then(changeAccessKey);
                });
    }, [accounts]);

    useEffect(() => {
        if (accessKey && accounts && accounts.length > 0) {
            AuthenticationManager.getInstance()
                .waitForToken()
                .then((token) => {
                    AccessKeyClient.generateQrCode(accounts[0].id, token.entity_id, accessKey.id).then((response) => changeQrCode(response.qr_code));
                });
        }
    }, [accessKey]);

    return { qrCode };
};

export default useAuthenticationQr;
